// Allows 
declare module '*.scss' {
    const styles: { [s: string]: string }
    export default styles
}

declare module '*.css' {
    const styles: { [s: string]: string }
    export default styles
}
